<template>
  <div id="page-user-view">
    <activity-edit-form
      :selected-activity="selectedActivity"
    ></activity-edit-form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import ActivityEditForm from "./ActivityEditForm";
import moment from "moment";
import Vue from "vue";
import VueLodash from "vue-lodash";
import _ from "lodash";

Vue.use(VueLodash, { name: "custom", lodash: _ });

export default {
  components: {
    ActivityEditForm
  },
  data() {
    return {
      itemPerPage: 15,
      page: 1,
      totalPages: 0,
      activities: [],
      showActivities: true,
      showActivityDetail: false,
      selectedActivity: {},
      categories: [],
      mailPopup: false,
      subject: "",
      content: ""
    };
  },
  methods: {
    ...mapActions("activity", [
      "fetchCompletedActivityByChildId",
      "fetchActivityByActivityIdAndChildId",
      "fetchActivityById"
    ]),
    ...mapActions("eylfCategory", ["fetchEYLFCategoryWithFrameworkByCenterId"]),
    ...mapActions("parent", ["sendParentRespondMailFromLearningStory"]),

    moment(date) {
      return moment(date).format("DD/MM/YY");
    },
    isEylfExist(key) {
      return _.includes(this.selectedActivity.eylfFrameworks, key);
    },
    async activitySelect(activityId) {
      const data = {
        activityId: activityId,
        childId: this.childId
      };
      this.showActivities = false;

      await this.fetchActivityByActivityIdAndChildId(data).then(response => {
        this.selectedActivity = response.data.data;
        this.showActivityDetail = true;
      });
    },
    async getEylfChecklist(centerId) {
      await this.fetchEYLFCategoryWithFrameworkByCenterId(centerId).then(
        checklist => {
          this.categories = checklist.data.data.eylfCategories;
        }
      );
    }
  },
  async mounted() {
    //this.getActivities(this.childId, this.page);
    //this.getEylfChecklist(this.child.learningCenterId);

    if (this.$route.params.activityId) {
      await this.fetchActivityById(this.$route.params.activityId).then(
        result => {
          this.selectedActivity = result.data.data;
        }
      );
    }
  },
  computed: {
    parent() {
      return this.$store.state.AppActiveUser;
    },
    child() {
      return JSON.parse(localStorage.getItem("child"));
    },
    children() {
      return localStorage.getItem("parentChildren");
    },
    childId() {
      return this.$route.params.id ? this.$route.params.id : this.child._id;
    }
  }
};
</script>
<style lang="scss">
.vs-tooltip {
  z-index: 100000;
}

.vs-tooltip {
  color: #222222;
  font-size: 15px;
  line-height: 20px;
  padding: 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
  min-width: 300px !important;
}
</style>
