<template>
  <div class="wrapper w-full">
    <div class="header flex">
      <h3 class="font-semibold leading-6 text-black">
        {{ data.title }}
      </h3>
      <span class="ml-auto block" v-on:click="collapsed = !collapsed">
          <vs-icon :icon=" collapsed ? 'add_circle' : 'remove_circle'" size="30px" color="#2D99D3"></vs-icon>
      </span>
    </div>
    <div class="collapsible" v-show="!collapsed">{{ data.description }}</div>
  </div>
</template>
<script>
export default {
  props: ['data', 'collapse'],
  data() {
    return {
      collapsed: true
    }
  },
  watch: {
    collapse(val) {
      this.collapsed = val;
    }
  }
}
</script>
